import { useState } from 'react';
import Tippy from '@tippyjs/react';
import { ToolMenu } from 'app/components';
import { i18n } from 'app/utils/i18n';
import { useHistory } from 'react-router-dom';
import { ConfirmationModal } from 'app/components';
import { useDeleteReferenceData } from './reference-data-queries';
import { Clickable } from 'app/components/Clickable';

const ReferenceDataDropdownMenu = ({ documentId, url, onChangeName, item, setShowDetails }) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [visble, setVisible] = useState(false);
  const [referenceInUseError, setReferenceInUseError] = useState(false);
  const deleteReferenceData = useDeleteReferenceData();

  const history = useHistory();

  const showTippy = () => setVisible(true);
  const hideTippy = () => setVisible(false);

  const toggleConfirmationModal = () => {
    setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
    hideTippy();
  };

  const deleteReference = () => {
    deleteReferenceData.mutate(
      {
        documentId,
        referenceData: item,
      },
      {
        onSuccess: () => {
          history.push(`/documents/${documentId}/reference-data`);
        },
        onSettled: () => {
          setShowDetails(false);
        },
        onError: (error) => {
          const response = JSON.parse(error.request.response);
          if (response.message === 'reference-in-use') {
            setReferenceInUseError(true);
          }
        },
      }
    );
  };

  const handleClickChangeName = () => {
    onChangeName(item);
    hideTippy();
  };

  const referenceMenuItems = (item) => [
    { text: i18n('reference-data.item.dropdown.edit-name'), icon: 'fa-regular fa-pen', onClick: () => handleClickChangeName() },
    { text: i18n('reference-data.item.dropdown.delete'), icon: 'fa-regular fa-trash-can', onClick: toggleConfirmationModal },
  ];

  return (
    <>
      <Tippy
        visible={visble}
        interactive={true}
        offset={[-90, -110]}
        trigger="click"
        onClickOutside={hideTippy}
        content={<ToolMenu menuItems={referenceMenuItems(item)} />}
      >
        <Clickable onClick={showTippy}>
          <i className="fa-regular fa-ellipsis-v p-2" onClick={showTippy} />
        </Clickable>
      </Tippy>
      <ConfirmationModal
        message={
          <>
            <i className="fa-regular fa-trash-can fs-1 mb-4" />
            <h3 className="confirm-delete-header">{i18n('reference-data.item.delete.confirm-title')}</h3>
            <span className="text-gray-900 fs-7">{i18n('reference-data.item.delete.confirm-text')}</span>
            {referenceInUseError && <div className="mt-3 text-red-500 fs-7">{i18n('reference-data.item.delete.in-use-error')}</div>}
          </>
        }
        title=" "
        show={showDeleteConfirmationModal}
        onConfirm={deleteReference}
        onDecline={toggleConfirmationModal}
        busy={deleteReferenceData.isLoading}
      />
    </>
  );
};

export default ReferenceDataDropdownMenu;
