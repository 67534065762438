import { i18n } from 'app/utils/i18n';
import { useGetDocument } from 'app/api/documents/document-queries';
import { Spinner } from 'app/components';
import { useStyle, useStyles } from 'app/api/style-queries';
import { Dropdown } from 'app/components';
import { UserContext } from 'app/state/contexts';
import { createNewDraft } from 'app/utils/draft';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useDraft, useSaveDraft } from 'app/api/draft-queries';

const propertyTypes = {
  color: 'color',
  font: 'font',
};

const fontOptions = [
  {
    value: 'Arial',
    title: 'Arial',
  },
  {
    value: 'Courier',
    title: 'Courier',
  },
  {
    value: 'Helvetica',
    title: 'Helvetica',
  },
];

const properties = {
  xrpPrimaryColor: {
    label: 'graphical-variable.xrpPrimaryColor',
    type: propertyTypes.color,
  },
  xrpSecondaryColor: {
    label: 'graphical-variable.xrpSecondaryColor',
    type: propertyTypes.color,
  },
  xrpTertiaryColor: {
    label: 'graphical-variable.xrpTertiaryColor',
    type: propertyTypes.color,
  },
  xrpQuaternaryColor: {
    label: 'graphical-variable.xrpQuaternaryColor',
    type: propertyTypes.color,
  },
  xrpHeadingColor: {
    label: 'graphical-variable.xrpHeadingColor',
    type: propertyTypes.color,
  },
  xrpFontFamily1: {
    label: 'graphical-variable.xrpFontFamily1',
    type: propertyTypes.font,
  },
  xrpFontFamily2: {
    label: 'graphical-variable.xrpFontFamily2',
    type: propertyTypes.font,
  },
};

const defaultVariables = {
  xrpHeadingColor: '#1e2533',
  xrpPrimaryColor: '#4db894',
  xrpSecondaryColor: '#085dad',
  xrpTertiaryColor: '#e0eff9',
  xrpQuaternaryColor: '#f1f5f9',
  xrpFontFamily1: 'Arial',
  xrpFontFamily2: 'Arial',
};

const propsOfType = (type) =>
  Object.entries(properties)
    .filter(([_, v]) => v.type === type)
    .map(([p]) => p);

const colorProperties = propsOfType(propertyTypes.color);
const fontProperties = propsOfType(propertyTypes.font);

export function GraphicalVariables() {
  const { documentId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const pDocument = useGetDocument(documentId, selectedOrganization);
  const fontIds = pDocument.data?.content.design.filter((s) => s.type === 'font').map((font) => font.styleId);
  const styleId = pDocument.data?.content.design.find((d) => d.type === 'graphical-variables')?.styleId;
  const style = useStyle({
    organization: selectedOrganization,
    documentSuperId: documentId,
    styleId,
  });
  const documentVariables = style.data?.content;
  const draft = useDraft({ documentSuperId: documentId });
  const draftVariables = draft.data?.content?.find((s) => s.type === 'graphical-variables')?.style;
  const { mutate: saveDraft } = useSaveDraft();
  const [stateVariables, setStateVariables] = useState();

  // TODO Let server deal with non-existent documentVariables
  const variables = { ...defaultVariables, ...(stateVariables || draftVariables || documentVariables || {}) };

  const [pushDraft] = useDelayedSave((newStateVariables) => {
    // TODO don't overwrite changes to the scss if draft exists
    const draftEntry = {
      type: 'graphical-variables',
      style: newStateVariables,
    };

    const newDraft = createNewDraft(pDocument, draft.data, draftEntry);

    saveDraft(newDraft, {
      onSuccess: () => {
        setStateVariables();
      },
    });
  }, 1000);

  const handlePropertyChange = (property, value) => {
    const newState = window.structuredClone(variables);
    newState[property] = value;

    setStateVariables(newState);
    pushDraft(newState);
  };

  return (
    <div>
      <h2 className="mb-3">{i18n('theme.graphical-variables.fonts')}</h2>
      <div className="d-flex flex-row gap-r-1">
        {fontProperties.map((property) => (
          <FontPicker key={property} font={variables[property]} property={property} onChange={handlePropertyChange} fontIds={fontIds} />
        ))}
      </div>
      <hr className="my-5 border-gray-300" />
      <h2 className="mb-3">{i18n('theme.graphical-variables.colors')}</h2>
      <div className="d-flex flex-row gap-r-1">
        {colorProperties.map((property) => (
          <ColorPicker key={property} color={variables[property]} property={property} onChange={handlePropertyChange} />
        ))}
      </div>
    </div>
  );
}

function ColorPicker(props) {
  const { color, property, onChange } = props;

  const handleChangeColor = (e) => {
    e.preventDefault();
    return onChange(property, e.target.value);
  };

  return (
    <>
      <div className="border border-lined border-blue-300 rounded bg-white p-2 d-flex flex-column" style={{ width: '149px' }}>
        <input type="color" className="all-unset d-block w-100 flex-grow-1 aspect-13x8 mb-2" value={color} onChange={handleChangeColor} />
        <div className="text-blue-700 fw-bold fs-7 mb-1">{i18n(properties[property].label)}</div>
        <div className="text-gray-800">{color}</div>
      </div>
    </>
  );
}

const FontItem = ({ item }) => {
  return (
    <div className="page-wrapper force-text" style={{ fontFamily: item.value }}>
      <div>{item.title}</div>
    </div>
  );
};

function FontPicker(props) {
  const { selectedOrganization } = useContext(UserContext);
  const { documentId } = useParams();

  const { font, property, onChange, fontIds } = props;
  const styleQuery = useStyles({ styleIds: fontIds, organization: selectedOrganization, documentSuperId: documentId });

  const handleFontChange = (value) => {
    return onChange(property, value);
  };

  if (styleQuery.isLoading) {
    return <Spinner />;
  }

  const customFonts = styleQuery.data?.map((style) => extractFontName(style.content)).map((fontName) => ({ value: fontName, title: fontName })) ?? [];

  return (
    <div className="flex-grow-1">
      <label className="form-label">{i18n(properties[property].label)}</label>
      <Dropdown items={[...fontOptions, ...customFonts]} value={font} onChange={handleFontChange} wrapper={FontItem} />
    </div>
  );
}

function extractFontName(style) {
  const fontFamilyRegex = /font-family:\s*['"]([^'"]+)['"]/;
  const match = style.match(fontFamilyRegex);
  return match[1];
}
