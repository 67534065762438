import { i18n } from 'app/utils/i18n';
import authStore from 'app/utils/authStore';
import { v4 as uuidv4 } from 'uuid';
import { useGetDocument } from 'app/api/documents/document-queries';
import { useDeleteTemplate, useDemoteTemplate, usePromoteTemplate, useSaveTemplate, useTemplate, useTemplates } from 'app/api/template-queries';
import { Modal, Spinner } from 'app/components';
import { UserContext } from 'app/state/contexts';
import produce from 'immer';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NO_DRAFT, useDraft, useSaveDraft } from 'app/api/draft-queries';
import Tippy from '@tippyjs/react';
import { Clickable } from 'app/components/Clickable';
import { Level } from './Level';
import { createNewDraft } from 'app/utils/draft';
import { usePermissions } from 'app/utils/hooks/document-permission';
import { Editor } from 'app/components/editor/Editor';
import withMentions from 'app/slate/extensions/mentions';
import withPage from 'app/slate/extensions/page';
import withParagraph from 'app/slate/extensions/paragraph';
import withLayout from 'app/slate/extensions/layout';
import { MAIN_EDITOR_ID } from 'app/state/contexts/EditorContext';
import { useRenameDocumentTemplate } from 'app/api/template-queries';

const extensions = [withLayout({ markEmpty: false }), withMentions, withPage({ withButtons: false, readOnly: true }), withParagraph({ withComments: false })];

const RenameModal = ({ show, onClose, template }) => {
  const { documentId } = useParams();
  const [name, setName] = useState(template?.name);

  useEffect(() => {
    setName(template.name);
  }, [template?.name]);

  const { mutate: renameTemplate } = useRenameDocumentTemplate();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    renameTemplate({ newName: name, documentSuperId: documentId, templateRevisionId: template.revisionId });
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <form onSubmit={handleSave}>
        <label className="form-label" htmlFor={`input-name-${template?.superId}`}>
          {i18n('theme.structure.name')}
        </label>
        <input className="form-control" id={`input-name-${template?.superId}`} value={name ?? ''} onChange={handleChange} />
        <div className="d-flex justify-content-end mt-5">
          <button type="button" className="btn" onClick={onClose}>
            {i18n('common.button.cancel')}
          </button>
          <button className="btn btn-primary">{i18n('common.button.save')}</button>
        </div>
      </form>
    </Modal>
  );
};

export function StyleSelection() {
  const { documentId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const pDocument = useGetDocument(documentId, selectedOrganization);
  const { documentPermissions } = usePermissions(documentId);
  const draft = useDraft({ documentSuperId: documentId });
  const { mutate: saveDraft } = useSaveDraft();
  const documentStyle = draft.data?.content?.find((s) => s.type === 'document') || pDocument.data?.content.design.find((s) => s.type === 'document');
  const template = useTemplate(documentStyle.templateRevisionId);
  const { mutate: saveTemplate } = useSaveTemplate();
  const [showModal, setShowModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  console.log('pDocument.data:', pDocument.data);
  console.log('template.data', template.data);
  console.log('documentStyle', documentStyle);

  const randomTemplateId = pDocument.data?.content.design.find((style) => style.type === 'template' && style.templateSuperId)?.templateSuperId;
  // const randomTemplateId = template.data?.superId
  // console.log('-->', pDocument.data?.content.design.find((style) => style.type === 'document'))
  console.log('randomTemplateId', randomTemplateId);

  const handleSaveDraft = (_, newStyle) => {
    // TODO don't overwrite changes to the scss if draft exists
    const draftEntry = {
      type: 'document',
      style: newStyle.style,
      templateSuperId: newStyle.superId,
      templateRevisionId: newStyle.revisionId,
    };

    const newDraft = createNewDraft(pDocument, draft.data, draftEntry);
    saveDraft(newDraft, {
      onSuccess: () => {},
    });
  };

  const handleCloseModal = () => setShowModal(false);
  const handleClickAddStyle = () => setShowModal(true);
  const handleDuplicateClick = () => {
    const styleCopy = produce(template.data, (s) => {
      s.superId = uuidv4();
      s.revisionId = uuidv4();
      s.name += ' (copy)';
      s.organization = selectedOrganization;
    });
    saveTemplate(styleCopy, {
      onSuccess: handleSaveDraft,
    });
  };

  if (!template.isSuccess || !pDocument.isSuccess || !draft.isSuccess) {
    return <Spinner />;
  }

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center mb-3">
        <h2 className="m-0">{i18n('theme.style')}</h2>
        <button
          onClick={handleClickAddStyle}
          type="button"
          className="btn-clear rounded-circle aspect-1x1 bg-green-700 text-white d-flex justify-content-center align-items-center "
          style={{ height: '43px', fontSize: '15px' }}
        >
          <i className="fa-regular fa-ellipsis-vertical fa-center-fix"></i>
        </button>
      </div>
      <div style={{ height: '124px' }} className="bg-light border border-blue-300 rounded align-items-center p-4 d-flex flex-row gap-r-2">
        <div className="h-100 d-flex flex-column w-100 justify-content-between">
          <div className="d-flex flex-row justify-content-between">
            <h3>{template.data.name}</h3>
            <SelectedStyle />
          </div>
          <div className="d-flex flex-row justify-content-between">
            <Level organization={template.data.organization} />
            <div className="d-flex flex-row gap-r-2">
              {documentPermissions.includes('CODER') && (
                <>
                  {randomTemplateId && authStore.hasPermission('TEMPLATE_UPDATE', template.data.organization) ? (
                    <>
                      {/* We need a template to exist in the document to be able to show style editor */}
                      <Link to={`templates/${randomTemplateId}`} className="d-flex flex-row align-items-center gap-r-0-5">
                        <i className="fal fa-pen"></i>
                        <span>{i18n('theme.style.edit-style')}</span>
                      </Link>
                    </>
                  ) : null}

                  <Clickable onClick={handleDuplicateClick} className="d-flex flex-row align-items-center gap-r-0-5">
                    <i className="fa-light fa-clone"></i>
                    <span>{i18n('theme.style.duplicate-style')}</span>
                  </Clickable>
                  {authStore.hasPermission('TEMPLATE_UPDATE', template.data.organization) ? (
                    <Clickable onClick={() => setShowRenameModal(true)} className="d-flex flex-row align-items-center gap-r-0-5">
                      <i className="fal fa-pen"></i>
                      <span>{i18n('theme.style.change-name')}</span>
                    </Clickable>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <StyleSelectionModal show={showModal} onClose={handleCloseModal} pDocument={pDocument.data} showRenameModal={() => setShowRenameModal(true)} />
      <RenameModal show={showRenameModal} template={template.data} onClose={() => setShowRenameModal(false)} />
    </>
  );
}

const SelectedStyle = () => {
  return (
    <div className="bg-blue-400 rounded-1 px-r-0-5 py-r-0-25 d-flex flex-row gap-r-0-5 align-items-center">
      <i className="fal fa-check"></i>
      <span>{i18n('theme.style.chosen-style')}</span>
    </div>
  );
};

/**
 * As regular useState but defaultValue value can change.
 * As long as the state is null the default is returned.
 * Works well with values from react-query that won't be
 * available on first render.
 */
function useStateOrDefault(defaultValue) {
  const [state, setState] = useState(null);
  return [state ?? defaultValue, setState];
}

function StyleSelectionModal({ show, onClose, pDocument }) {
  const scopes = {
    standard: 'standard',
    organization: 'organization',
  };
  const { selectedOrganization } = useContext(UserContext);
  const { documentPermissions } = usePermissions(pDocument.superId);
  const { mutate: saveDraft } = useSaveDraft();
  const templates = useTemplates({ organization: selectedOrganization, documentSuperId: pDocument.superId, type: 'style-preview' });
  const draft = useDraft({
    documentSuperId: pDocument.superId,
    documentRevisionId: pDocument.revisionId,
    organization: pDocument.organization,
  });
  const [scope, setScope] = useState(scopes.standard);
  const styles = useTemplates({
    organization: selectedOrganization,
    type: 'document',
  });
  const currentTemplateSuperId = pDocument.content.design.find((s) => s.type === 'document')?.templateSuperId;
  const currentDraftStyle = draft.data?.content?.find((s) => s.type === 'document');
  const currentStyle = styles.data?.find((s) => s.superId === (currentDraftStyle?.templateSuperId || currentTemplateSuperId));
  const [selectedStyle, setSelectedStyle] = useStateOrDefault(currentStyle);

  const handleSaveNewStyle = () => {
    if (selectedStyle === currentStyle) {
      onClose();
      return;
    }

    const emptyDraft = {
      documentSuperId: pDocument.superId,
      documentRevisionId: pDocument.revisionId,
      organization: pDocument.organization,
      content: [],
    };

    // TODO don't overwrite changes to the scss if draft exists
    const draftEntry = {
      type: 'document',
      style: selectedStyle.style,
      templateSuperId: selectedStyle.superId,
      templateRevisionId: selectedStyle.revisionId,
    };

    const base = draft.data === NO_DRAFT ? emptyDraft : draft.data;
    const newDraft = produce(base, (d) => {
      const i = d.content.findIndex((e) => e.type === 'document');
      const shouldDelete = i === -1 ? 0 : 1;
      d.content.splice(i, shouldDelete, draftEntry);
    });

    saveDraft(newDraft, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  if (!styles.isSuccess) {
    return (
      <Modal show={show} onClose={onClose} size="xl">
        <Spinner />
      </Modal>
    );
  }

  const filteredStyles = scope === scopes.standard ? styles.data.filter((s) => !s.organization) : styles.data.filter((s) => !!s.organization);

  const previewSection = [templates.data?.[0]?.structure];
  console.log('user is CODER?:', documentPermissions.includes('CODER'));
  return (
    <Modal titleLabelKey="theme.style.select-style" show={show} onClose={onClose} size="xl">
      <div className="body d-flex flex-row gap-r-2">
        <div className="d-flex flex-column gap-r-1">
          <div>
            <ul className={`nav nav-pills bg-light mb-2 p-1 border border-line rounded d-inline-flex`}>
              <li className="nav-item">
                <button onClick={() => setScope(scopes.standard)} className={`btn-clear nav-link ${scope === scopes.standard ? 'active' : ''}`}>
                  {i18n('theme.level.system')}
                </button>
              </li>
              <li className="nav-item">
                <button onClick={() => setScope(scopes.organization)} className={`btn-clear nav-link ${scope === scopes.organization ? 'active' : ''}`}>
                  {i18n('theme.level.organization')}
                </button>
              </li>
            </ul>
          </div>
          <div className="overflow-auto">
            <h3 className="mt-4">{scope === scopes.standard ? i18n('theme.style.standard-styles') : i18n('theme.style.organization-styles')}</h3>
            {filteredStyles.map((style) => {
              const isSelected = style === selectedStyle;
              return (
                <div
                  key={style.revisionId}
                  className={`d-flex flex-column border border-blue-${isSelected ? '800' : '500'} rounded mb-3 overflow-hidden`}
                  style={{ height: '160px' }}
                >
                  <Clickable key={style.revisionId} className="position-relative d-flex flex-column flex-grow-1" onClick={() => setSelectedStyle(style)}>
                    <div className="position-absolute top-0 end-0 me-3 mt-3">{isSelected && <SelectedStyle />}</div>
                    <div className="position-absolute bottom-0 end-0 me-3 mb-2">
                      <Level organization={style.organization} />
                    </div>
                  </Clickable>
                  <div
                    className={`d-flex flex-row justify-content-between border-top border-line border-blue-800 ${isSelected ? 'bg-blue-400' : 'bg-white'} p-3`}
                  >
                    <div>{style.name}</div>
                    {documentPermissions.includes('CODER') && (
                      <>
                        <Tippy content={<StyleMenu style={style} />} interactive={true} trigger="click" placement="right-end" theme="light">
                          <Clickable className="fs-3">
                            <i className="far fa-ellipsis-v"></i>
                          </Clickable>
                        </Tippy>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center border border-line border-blue-800">
          {templates.isSuccess && (
            <Editor
              extensions={extensions}
              editorId={MAIN_EDITOR_ID}
              readOnly
              useDraft
              showErrors={false}
              withStyle={selectedStyle?.superId}
              usingStaticStructure={previewSection}
              instance={{
                documentId: pDocument.superId,
              }}
              forcePaperFormat
              useReferenceData={false}
            ></Editor>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5">
        <button className="btn" onClick={onClose}>
          {i18n('common.button.cancel')}
        </button>
        <button className="btn btn-primary" onClick={handleSaveNewStyle}>
          {i18n('theme.style.confirm-style-select')}
        </button>
      </div>
    </Modal>
  );
}

function StyleMenu({ style, tippyRef }) {
  const { selectedOrganization } = useContext(UserContext);
  const { mutate: deleteTemplate } = useDeleteTemplate();
  const { mutate: promoteTemplate } = usePromoteTemplate();
  const { mutate: demoteTemplate } = useDemoteTemplate();
  const { mutate: saveTemplate } = useSaveTemplate();
  const { mutate: saveDraft } = useSaveDraft();
  const [showRenameModal, setShowRenameModal] = useState();
  const { documentId } = useParams();
  const pDocument = useGetDocument(documentId, selectedOrganization);
  const draft = useDraft({ documentSuperId: documentId });

  const handleDuplicateClick = () => {
    const styleCopy = produce(style, (s) => {
      s.superId = uuidv4();
      s.revisionId = uuidv4();
      s.name += ' (copy)';
      s.organization = selectedOrganization;
    });
    saveTemplate(styleCopy, {
      onSuccess: handleSaveDraft,
    });
  };

  const handleSaveDraft = (_, newStyle) => {
    // TODO don't overwrite changes to the scss if draft exists
    const draftEntry = {
      type: 'document',
      style: newStyle.style,
      templateSuperId: newStyle.superId,
      templateRevisionId: newStyle.revisionId,
    };

    const newDraft = createNewDraft(pDocument, draft.data, draftEntry);
    saveDraft(newDraft, {
      onSuccess: () => {},
    });
  };

  const liClasses = 'd-flex flex-row gap-r-1 align-items-center mb-1';
  return (
    <div>
      <div className="bg-white rounded p-3 border border-line border-grey-300">
        <ul className="mb-0">
          <li className={liClasses}>
            <i className="fal fa-clone"></i>
            <Clickable onClick={handleDuplicateClick}>{i18n('theme.style.duplicate-style')}</Clickable>
          </li>
          {authStore.hasPermission('TEMPLATE_UPDATE', style.organization) && (
            <li className={liClasses}>
              <i className="fal fa-pen"></i>
              <Clickable onClick={() => setShowRenameModal(true)}>{i18n('theme.style.change-name')}</Clickable>
            </li>
          )}
          {authStore.hasGlobalPermission('TEMPLATE_CREATE') && (
            <li className={liClasses}>
              <i className="fal fa-trash"></i>
              <Clickable onClick={() => promoteTemplate(style) && tippyRef.current.hide()}>{i18n('theme.style.promote-style')}</Clickable>
            </li>
          )}
          {authStore.hasGlobalPermission('TEMPLATE_DELETE') && (
            <li className={liClasses}>
              <i className="fal fa-trash"></i>
              <Clickable onClick={() => demoteTemplate({ template: style, organization: selectedOrganization }) && tippyRef.current.hide()}>
                {i18n('theme.style.demote-style')}
              </Clickable>
            </li>
          )}
          {authStore.hasPermission('TEMPLATE_DELETE', style.organization) && (
            <li className={liClasses}>
              <i className="fal fa-trash"></i>
              <Clickable onClick={() => deleteTemplate(style) && tippyRef.current.hide()}>{i18n('theme.style.delete-style')}</Clickable>
            </li>
          )}
        </ul>
      </div>
      <RenameModal show={showRenameModal} template={style} onClose={() => setShowRenameModal(false)} />
    </div>
  );
}
