import numfmt from 'numfmt';
import XLSX from 'xlsx';

const CHAR_OFFSET = 64;

export class MissingSheet extends Error {}

export const getSelectedCells = (selection) => {
  const cells = [];

  if (!selection?.end || !selection?.start) {
    return cells;
  }

  const [rowStart, rowEnd] = [selection.start.i, selection.end.i].sort();
  const [columnStart, columnEnd] = [selection.start.j, selection.end.j].sort();

  for (let x = rowStart; x <= rowEnd; x++) {
    for (let y = columnStart; y <= columnEnd; y++) {
      cells.push({ x, y });
    }
  }

  return cells;
};

const isKeySelectedInAllPoints = (value = '', key = '', data, selection) => {
  if (!selection?.end || !selection?.start || !Array.isArray(data?.selectionContent)) {
    return;
  }

  let isSelected = true;

  getSelectedCells(selection).forEach(({ x, y }) => {
    if (data?.selectionContent[x][y]?.[key] !== value) {
      isSelected = false;
    }
  });

  return isSelected;
};

export const isStylingSelected = (value = '', data, selection) => {
  return isKeySelectedInAllPoints(value, 'styling', data, selection);
};

export const getSelectedDropdownValue = (key = '', data, selection, values = []) => {
  const selectedFormats = [];

  getSelectedCells(selection).forEach(({ x, y }) => {
    const selectedFormat = data?.selectionContent[x][y]?.[key] || values[0].value;

    if (selectedFormat && !selectedFormats.includes(selectedFormat)) {
      selectedFormats.push(selectedFormat);
    }
  });

  if (selectedFormats.length !== 1) {
    return values[0];
  }

  const selectedValue = values.find((v) => v.value === selectedFormats[0]);

  return selectedValue || values[0];
};

export function parseXlsxData(data, selectedSheet, locale) {
  if (data === undefined || selectedSheet === undefined) {
    throw new Error('empty parameters');
  }

  const workbook = XLSX.read(data.fileContent, {
    type: 'base64',
    sheets: [selectedSheet],
    cellStyles: true,
    cellDates: true,
  });

  const sheet = workbook.Sheets[selectedSheet];

  if (!sheet) {
    throw new MissingSheet();
  }

  /*
     Returns bounding box of the sheet. O(memsize) can be huge. Better way should be implemented.
     Returns string on format 'A1:N9'
    */
  const ref = workbook.Sheets[selectedSheet]['!ref'];

  if (!ref) {
    throw new Error('ref missing');
  }
  /*
      Gets the number of columns from the bounding box above. Extracts the right hand column in the @ref and converts that
      column to correct column position.
    */
  const startEnd = ref.split(':');

  const numberOfColumns = !!startEnd[1]
    ? startEnd[1]
        .match(/[A-Z]+/g)[0]
        .split('')
        .map((item, position) => {
          return item.charCodeAt(0) - CHAR_OFFSET + 90 * position;
        })
        .reduce((total, num) => total + num, 0)
    : 1; // Only one cell in width

  /*
      Gets the number of row returned by the bounding box
    */

  const numberOfRows = !!startEnd[1] ? parseInt(startEnd[1].match(/[0-9]+/g)[0]) : parseInt(startEnd[0].match(/[0-9]+/g)[0]);
  /*
   Find all hidden columns
   */
  let hiddenColumns = [];
  if (!!sheet['!cols']) {
    hiddenColumns = sheet['!cols']
      .map((item, index) => [index, item])
      .filter(([index, item]) => !!item && item.hidden)
      .map(([index]) => index);
  }

  /*
      Get all indexes of rows that are hidden in the excel file.
    */
  let hiddenRows = [];
  if (!!sheet['!rows']) {
    hiddenRows = sheet['!rows']
      .map((item, index) => [index, item.hidden])
      .filter(([i, hidden]) => hidden)
      .map(([index]) => index);
  }

  const correctedColumnCount = numberOfColumns - hiddenColumns.length;
  const correctedRowCount = numberOfRows - hiddenRows.length;
  const dataMatrix = Array(correctedRowCount)
    .fill()
    .map(() => Array(correctedColumnCount).fill('')); // Prefill matrix with empty strings
  let skippedRows = 0;
  for (let row = 0; row < numberOfRows; row++) {
    if (hiddenRows.includes(row)) {
      skippedRows++;
      continue;
    }

    let skippedColumns = 0;
    for (let column = 0; column < numberOfColumns; column++) {
      if (hiddenColumns.includes(column)) {
        skippedColumns++;
        continue;
      }

      const cell = sheet[transformToColumn(column) + (row + 1)];
      const locales = {};

      /**
       * Criteria
       * 1. Check if cell is not undefined
       * 2. Check if cell type is 'n' (number)
       * 3. Check if parsed value is not null/undefined
       * 4. Check if parsed value is a valid number that can be parsed (This is to catch the dash ('-') representing the zero value)
       */

      if (cell?.t === 'n') {
        const excelBadRounded = cell.v.toString().substr(-5) === '99999';
        //https://github.com/SheetJS/sheetjs/issues/1105 Excel is storing 1.2345 as 1.2344999999999999 so the rounding fails
        const newV = excelBadRounded ? cell.v + 0.0000000000000001 : cell.v;
        locales['sv-SE'] = numfmt.format(cell.z, newV, { locale: 'sv-SE' });
        locales['en-US'] = numfmt.format(cell.z, newV, { locale: 'en-US' });
      }

      /*
        We use the parsed date value by SheetJS, since the raw value when it's parsed has rounding error in it. See issue
        https://github.com/SheetJS/sheetjs/issues/1212
      */
      if (cell?.t === 'd') {
        locales['en-US'] = cell.w;
        locales['sv-SE'] = Intl.DateTimeFormat('sv-SE').format(new Date(cell.w));
      }

      const value = ['d', 'n'].includes(cell?.t) ? locales[locale] : cell?.w;

      dataMatrix[row - skippedRows][column - skippedColumns] = {
        readOnly: false,
        value: value,
        rawData: cell,
        locales: locales,
      };
    }
  }
  return dataMatrix;
}

function transformToColumn(num) {
  let columnName = '';

  while (num >= 0) {
    columnName = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + columnName;
    num = Math.floor(num / 26) - 1;
  }

  return columnName;
}

export function parseSheetOptions(data) {
  const workbook = XLSX.read(data.fileContent, { type: 'base64', bookSheets: true });
  return workbook.SheetNames.map((sheet, index) => ({
    value: index,
    label: sheet,
  }));
}

const MIN_PARTIAL_FILE_MATCH = 4;
export function findSuitableNewFile(currentReferenceData, currentFile, files) {
  // First time select, don't pick anything
  if (!currentReferenceData.fileRevisionId) {
    return undefined;
  }

  // Find latest with same name
  const indexOfCurrent = files.indexOf(currentFile);
  const indexOfExact = files.findIndex((f) => f.filename === currentFile.filename);
  const isNewer = indexOfExact < indexOfCurrent;
  if (indexOfExact >= 0 && isNewer) {
    return files[indexOfExact];
  }

  // Find as good match as possible
  const otherFiles = files.filter((f) => f !== currentFile);
  let partialMatch;
  for (let i = MIN_PARTIAL_FILE_MATCH; i < currentFile.filename.length; i++) {
    const needle = currentFile.filename.slice(0, i);
    const indexOfMatch = otherFiles.findIndex((f) => f.filename.startsWith(needle));
    if (indexOfMatch === -1) {
      break;
    }

    if (indexOfMatch < indexOfCurrent) {
      partialMatch = files[indexOfMatch];
    }
  }

  // Will be undefined if no good match was found
  return partialMatch;
}
