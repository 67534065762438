import React, { useEffect, useState } from 'react';
import { toggleBlock } from 'app/slate/functions';
import { useSlate } from 'slate-react';
import { i18n } from 'app/utils/i18n';
import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { toolbarCSSExtractor } from 'app/utils/css';
import { Dropdown } from 'app/components';

export const getActiveBlockId = (node, formats) => {
  const activeFormat = node?.formatting?.format;
  const activeClass = node?.formatting?.class;

  if ((activeFormat || activeClass) && Array.isArray(formats)) {
    const selectedFormat = formats.find((f) => {
      return (activeFormat && f.value.format === activeFormat) || (activeClass && f.value.class === activeClass);
    });

    if (selectedFormat) {
      return selectedFormat;
    }
  }
  return null;
};

/**
 * When the user clicks a new page that has a different template see if that template has new
 * typography options under the .toolbar class (this is done in toolbarCSSExtractor())
 *
 * @param {*} reduxActiveSection
 * @param {*} reduxDocumentCSS
 * @returns
 */
export const getOptionsForCurrentTemplate = (reduxActiveSection = {}, toolbarDefinition = []) => {
  if (!reduxActiveSection) {
    return [];
  }

  //Fetch global toolbar layout
  const active = toolbarDefinition.filter((toolbar) => toolbar.templateId === undefined || toolbar.templateId === reduxActiveSection.templateSuperId) ?? [];

  // Map toolbar definitions to dropdown options. Sorted alphabetically
  const options = active
    .flatMap((toolbar, index) => {
      return toolbar.options.map((option) => {
        let entry = {
          id: index,
          value: {},
          title: option.title,
          requiredClasses: toolbar.wrapperClasses,
        };

        // if the type starts with a . it is a CSS class
        if (option.selector.startsWith('.')) {
          entry.value['class'] = option.selector.replace('.', '');
        } else {
          // Else it will be a HTML-tag
          entry.value['format'] = option.selector;
        }
        return entry;
      });
    })
    .sort((a, b) => a.title.localeCompare(b.title));

  return options;
};

const DropItemComponent = ({ item }) => {
  let child = (
    <p className={`m-0 ${item.value?.class}`}>
      <span>{item.title}</span>
    </p>
  );

  switch (item.value?.format) {
    case 'h1':
      child = <h1 className="fs-3 my-0">{item.title}</h1>;
      break;
    case 'h2':
      child = <h2 className="fs-4 my-0">{item.title}</h2>;
      break;
    case 'h3':
      child = <h3 className="fs-5 my-0">{item.title}</h3>;
      break;
    case 'h4':
      child = <h4 className="fs-6 my-0">{item.title}</h4>;
      break;
    case 'h5':
      child = <h5 className="fs-6 my-0">{item.title}</h5>;
      break;
    case 'h6':
      child = <h6 className="fs-6 my-0">{item.title}</h6>;
      break;
    default:
      break;
  }

  let innerClass = !!item.requiredClasses ? item.requiredClasses[item.requiredClasses.length - 1] : '';
  return (
    <div className="page-wrapper force-text">
      <div className={innerClass}>{child}</div>
    </div>
  );
};

export const MarkHeadingDropdown = () => {
  const defaultOptions = [
    {
      value: { format: 'paragraph' },
      title: i18n('dropdown.format.paragraph'),
    },
    {
      value: { format: 'h1' },
      title: i18n('dropdown.format.heading-1'),
    },
    {
      value: { format: 'h2' },
      title: i18n('dropdown.format.heading-2'),
    },
    {
      value: { format: 'h3' },
      title: i18n('dropdown.format.heading-3'),
    },
  ];
  const [value, setValue] = useState();
  const [options, setOptions] = useState(defaultOptions);
  const editor = useSlate();

  const reduxActiveSection = useEditorSelector((editor) => editor.activeSection);
  const reduxActiveNode = useEditorSelector((editor) => editor.activeNode);
  const reduxDocumentCSS = useEditorSelector((editor) => editor.pDocument).css;

  useEffect(() => {
    const block = getActiveBlockId(reduxActiveNode, options);
    setValue(block?.value || options[0]);
  }, [reduxActiveNode, options]);

  useEffect(() => {
    if (reduxActiveSection?.type) {
      const options = getOptionsForCurrentTemplate(reduxActiveSection, toolbarCSSExtractor(reduxDocumentCSS));
      setOptions(options.length > 0 ? options : defaultOptions);
    }
    // eslint-disable-next-line
  }, [reduxActiveSection, reduxDocumentCSS]);

  const handleChange = (newFormat) => {
    setValue(newFormat);
    toggleBlock(editor, 'custom-formatting', newFormat);
  };

  return <Dropdown sm items={options} value={value} onChange={handleChange} wrapper={DropItemComponent} />;
};
