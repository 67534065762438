export const toolbarCSSExtractor = (css) => {
  if (!!css?.match) {
    // Find toolbars that are global, eg. directly below .page-content
    const gloablToolbarRegex = new RegExp(/\.page-content .toolbar\s?{.*?(?<=}(\n|$))/, 'gsm');

    // Find toolbars specific to a template (page structure)
    const templateToolbarRegex = new RegExp(/\.xrp-template([-_*a-zA-Z0-9]+)?\s?.toolbar\s?{.*?(?<=}(\n|$))/, 'gsm');

    // Find the class wrapping .toolbar (eg. .page-content or .xrp-template-XXX)
    const wrapperClassRegex = new RegExp(/(([-_a-zA-Z*])*)(?=(.*).\.toolbar {.*)/);

    // Extract json between "/*! { foo: "bar" } */"
    const jsonExtractorRegex = new RegExp(/(?<=\/\*!).*(?=\*\/)/, 'm');

    let globalToolbar = css.match(gloablToolbarRegex) ?? [];
    let templateToolbars = css.match(templateToolbarRegex)?.filter(onlyUnique) ?? []; // Duplicates can occur when page specific styling exist
    const toolbarCss = [...globalToolbar, ...templateToolbars];

    if (!!toolbarCss) {
      const toolbars = toolbarCss.map((toolbar) => {
        let wrapperClass = toolbar.match(wrapperClassRegex).find((className) => className) ?? '';
        let toolbarDefinition = {};

        try {
          toolbarDefinition = JSON.parse(toolbar.match(jsonExtractorRegex));
        } catch (e) {
          console.warn('Could not JSON.parse this string', toolbar.match(jsonExtractorRegex));
        }

        return {
          options: Object.entries(toolbarDefinition).map(([selector, title]) => ({
            selector,
            title,
          })),
          wrapperClass,
          templateId: wrapperClass.split('xrp-template-')[1], // undefined if global
        };
      });

      return toolbars;
    }
  }

  return [];
};

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
