import { useState, useEffect, useContext } from 'react';
import { useDocumentMetadataSave, useGetDocument } from 'app/api/documents/document-queries';
import { useParams } from 'react-router-dom';
import { i18n } from 'app/utils/i18n';
import { LanguageSelector } from 'app/components';
import { UserContext } from 'app/state/contexts';
import { useDelayedSave } from 'app/utils/hooks/delayed-save';

const Metadata = () => {
  const { documentId } = useParams();
  const { selectedOrganization } = useContext(UserContext);
  const { data: pDocument } = useGetDocument(documentId, selectedOrganization);
  const [language, setLanguage] = useState(pDocument?.content.locale);
  const [texts, setTexts] = useState({ [language]: defaultTexts });
  const { mutateAsync: save } = useDocumentMetadataSave();
  const [delayedSave] = useDelayedSave(save, 1000);

  const handleFreeTextChange = (key, newValue) => {
    const languageText = texts[language] || defaultTexts;
    const newLanguageText = { ...languageText, [key]: newValue };
    const newTexts = { ...texts, [language]: newLanguageText };
    const oldMetadata = pDocument.content.metadata;
    const { superId, revisionId } = pDocument;
    delayedSave({ documentSuperId: superId, documentRevisionId: revisionId, metadata: { ...oldMetadata, texts: newTexts } });

    setTexts(newTexts);
  };

  useEffect(() => {
    if (pDocument) {
      setTexts(pDocument.content?.metadata?.texts || { [pDocument.content.locale]: defaultTexts });
      setLanguage(pDocument.content.locale);
    }
  }, [pDocument]);

  return (
    <div>
      <h2 className="fs-1 mb-4">{i18n('project-details.metadata.free-texts')}</h2>
      <div className="my-5 w-25">
        <LanguageSelector locale={language} onChange={setLanguage} />
      </div>
      {Object.entries(texts?.[language] || defaultTexts).map(([key, value]) => (
        <div key={key} className="row mb-2">
          <label className="col-2 col-form-label">{i18n('project-details.metadata.prop.' + key)}</label>
          <div className="col-5 d-flex align-items-center">
            <input className="form-control" onChange={(e) => handleFreeTextChange(key, e.target.value)} value={value} type="text" />
          </div>
        </div>
      ))}
      <hr className="my-4 border-gray-300" />
    </div>
  );
};

export default Metadata;

const defaultTexts = { text1: '', text2: '', text3: '', text4: '', text5: '', text6: '', text7: '', text8: '', text9: '' };
