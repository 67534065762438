import { DelayedDataSheet } from 'app/components/DelayedDataSheet';

function StyledDataSheet(props) {
  const { locale, highlightedCells, ...rest } = props;
  return (
    <DelayedDataSheet
      className="table--reference-data"
      overflow="wrap"
      valueRenderer={valueRenderer(locale)}
      cellRenderer={(props) => cellRenderer(props, highlightedCells)}
      onCellsChanged={onCellsChanged}
      onContextMenu={onContextMenu}
      {...rest}
    />
  );
}

export function onCellsChanged(changes) {
  const grid = this.xlsData;
  changes.forEach(({ cell, row, col, value }) => {
    grid[row][col] = { ...grid[row][col], value };
  });
  this.setState({ grid });
}

export function onContextMenu(e, cell, i, j) {
  return cell.readOnly ? e.preventDefault() : null;
}

// Need to return new renderer every time for ReactDataSheet
// since it won't react to user changing cell styles otherwise
export function valueRenderer(locale) {
  return function valueRenderer(cell) {
    cell.readOnly = true;

    if (!!locale && !!cell.locales && Object.keys(cell.locales).length) {
      return cell.locales[locale] || cell.value;
    }

    return cell.value;
  };
}

export function cellRenderer(props, highlightedCells) {
  const { row, col, cell, children, attributesRenderer, updated, editing, className: _className, ...rest } = props;
  const isHighlighted = !!highlightedCells?.find((highlight) => row === highlight.row && col === highlight.col);

  const classNames = [_className, cell.format?.replace('.', ''), cell.background, cell.border];

  if (isHighlighted) {
    classNames.push('bg-warning');
  }

  const className = classNames.join(' ');
  if (cell.styling === 'bold') {
    return (
      <td className={className} {...rest} style={{}}>
        <b>{props.children}</b>
      </td>
    );
  } else if (cell.styling === 'italic') {
    return (
      <td className={className} {...rest} style={{}}>
        <i>{props.children}</i>
      </td>
    );
  } else if (cell.styling === 'underline') {
    return (
      <td className={className} {...rest} style={{}}>
        <u>{props.children}</u>
      </td>
    );
  } else if (cell.format === 'thead') {
    return (
      <th className={className} {...rest} style={{}}>
        {props.children}
      </th>
    );
  } else {
    return (
      <td className={className} {...rest} style={{}}>
        {props.children}
      </td>
    );
  }
}

export default StyledDataSheet;
