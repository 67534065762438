import * as DOMPurify from 'dompurify';
import { useMemo } from 'react';
const DataImage = (props) => {
  const { fileType, fileContent, style, filename } = props;
  const cleanData = useMemo(() => {
    if (fileType === 'image/svg+xml') {
      return DOMPurify.sanitize(fileContent);
    } else {
      return `data:${fileType};base64,${fileContent}`;
    }
  }, [fileContent, fileType]);

  if (fileType === 'image/svg+xml') {
    return <span dangerouslySetInnerHTML={{ __html: cleanData }} />;
  } else {
    return <img src={cleanData} style={style} alt={filename} />;
  }
};

export default DataImage;
