import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useDraggableList } from 'app/utils/hooks/useDraggableList';

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <button
    className="bg-transparent border-0  me-2 wr-2 aspect-1x1 d-flex align-items-center justify-content-center rounded-circle hover-bg-blue-300"
    ref={ref}
    onClick={onClick}
  >
    <i className="fa-regular fa-ellipsis-v" />
  </button>
));

const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <ul className="list-unstyled mb-0">{children}</ul>
    </div>
  );
});

function DetailsMenu({ items }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu className="border-gray-300 rounded-sm" as={CustomMenu}>
        {items.map((item) => (
          <Dropdown.Item key={item.title} className="px-3 py-2 d-flex align-items-center" as={Link} onClick={item.onClick}>
            {item.icon ? <i className={`fa-regular fa-${item.icon} me-2`} /> : null}
            <div>{item.title}</div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function RowComponent({ item, className, children }) {
  if (item.disabled) {
    return <div className={className}>{children}</div>;
  }

  if (item.linkTo) {
    return (
      <Link className={className} to={item.linkTo}>
        {children}
      </Link>
    );
  }

  if (item.onClick) {
    return (
      <button onClick={item.onClick} className={className}>
        {children}
      </button>
    );
  }

  return <div className={className}>{children}</div>;
}

const SimpleTable = ({ items, onSort }) => {
  const {
    reorderedList: sortedItems,
    draggedItem,
    draggableAttributes,
  } = useDraggableList(items, (reorderedList) => {
    onSort(reorderedList);
  });

  return (
    <ul>
      {(sortedItems || items).map((item, idx) => (
        <li
          key={item.id || idx}
          {...draggableAttributes(item)}
          className={`bg-white d-flex my-3 align-items-center ${item === draggedItem ? 'opacity-25' : ''} ${item.disabled ? 'text-gray-700' : ''}`}
        >
          {onSort ? (
            <button className="bg-transparent border-0 draggable-handle ps-4">
              <i className="fa-solid fa-grip-vertical text-blue-500" />
            </button>
          ) : null}
          <RowComponent item={item} className="w-100 bg-transparent border-0 d-flex justify-content-between align-items-center fs-4 px-4 py-3">
            <span className={item.selected ? 'text-blue-800 fw-semibold' : ''}>
              {item.title}
              {item.selected ? <i className="ms-3 fa-solid fa-check" /> : null}
            </span>
            <div className="text-gray-700">{item.detail}</div>
          </RowComponent>
          <div>{item.dropdownItems && !item.disabled ? <DetailsMenu items={item.dropdownItems} /> : null}</div>
        </li>
      ))}
    </ul>
  );
};

export default SimpleTable;
