import { evolve, is, map, pick, pipe, when } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { Node } from 'slate';

export const centeredPopperElement = (reference) => ({
  getBoundingClientRect() {
    if (!reference) {
      return {};
    }

    const searchRef = reference instanceof Selection ? reference.anchorNode.parentElement : reference;
    const pageBox = searchRef.closest('.page-wrapper').getBoundingClientRect();

    const referenceElement = reference instanceof Selection ? reference.getRangeAt(0) : reference;
    const referenceBox = referenceElement.getBoundingClientRect();

    return {
      ...pick(['top', 'bottom', 'height', 'y'], referenceBox),
      ...pick(['left', 'right', 'width', 'x'], pageBox),
    };
  },
});

/**
 * Helper function to use when you need to get hold of the reference that slate uses for nodes
 * @param {*} attributes the slate attributes
 * @param {*} setRef your own reference, set state function or ref
 */
export const refHandler = (attributes, setRef) => (ref) => {
  attributes.ref.current = ref;
  if (setRef instanceof Function) {
    setRef(ref);
  } else {
    setRef.current = ref;
  }
};

export const hasText = (node) => {
  for (const [n] of Node.texts(node)) {
    if (n.text) {
      return true;
    }
  }
  return false;
};

/**
 * Returns a new copy of the object where all occurances of uuid are changed to newly generated uuids.
 */
export const evolveUuids = when(
  is(Object),
  pipe(
    evolve({ uuid: () => uuidv4() }),
    map((a) => evolveUuids(a))
  )
);
