import React, { useEffect, useState, useCallback } from 'react';
import { useImage } from 'app/api/documents/image-queries';
import { useParams } from 'react-router-dom';
import { FileZone } from 'app/components';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { Spinner } from 'app/components';
import { ConfirmationModal, TippyMenu, Modal, ToolMenu, DataImage } from 'app/components';
import request from 'app/api/request';
import { i18n } from 'app/utils/i18n';
import { useChangeImageName } from 'app/api/documents/image-queries';
import { formatDate } from 'app/utils/date';
import { useCss } from 'app/api/style-queries';

const Picture = ({ listType, image, imageMenuItems }) => {
  const { data } = useImage(image.revisionId);

  if (!data) {
    return <Spinner />;
  }

  function renderBoxPicture() {
    return (
      <Tippy
        interactive
        offset={[110, -20]}
        followCursor="initial"
        trigger="click"
        plugins={[followCursor]}
        content={<ToolMenu menuItems={imageMenuItems(data)} />}
      >
        <div className="picture">
          <div className="img-wrapper">
            <DataImage {...data} />
          </div>
          <div className="file-name">{data.filename}</div>
        </div>
      </Tippy>
    );
  }

  function renderListPicture() {
    return (
      <tr>
        <td className="image-cell">
          <DataImage {...data} />
        </td>
        <td>{data.filename}</td>
        <td>{formatDate(data.createdAt)}</td>
        <td>
          <TippyMenu menu={imageMenuItems(data)} />
        </td>
      </tr>
    );
  }

  switch (listType) {
    case 'box':
      return renderBoxPicture();
    case 'list':
    default:
      return renderListPicture();
  }
};

const UploadFile = ({ old, onSuccess, documentId }) => {
  return (
    <div className="add-new-popup">
      <FileZone type="img" old={old} documentId={documentId} onSuccess={onSuccess} />
    </div>
  );
};

const Pictures = () => {
  const LIST_TYPES = ['list', 'box'];
  const { documentId } = useParams();
  const [doc, setDoc] = useState();
  const css = useCss({ documentSuperId: documentId });
  const [listType, setListType] = useState(LIST_TYPES[1]);
  const [currentImage, setCurrentImage] = useState('');
  const [showImgInfo, setShowImgInfo] = useState(false);
  const [modalNewNameOpen, setModalNewNameOpen] = useState(false);
  const [errorImageName, setErrorImageName] = useState(false);
  const [imageName, setImageName] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [imageInUseError, setImageInUseError] = useState(false);
  const { mutate: changeImageName } = useChangeImageName();

  function validName(documentName) {
    return documentName.trim().length > 0 && documentName.trim().length < 65;
  }

  const imageMenuItems = (image) => [
    {
      text: i18n('pictures.dropdown.item.upload-new'),
      icon: 'fa-pen',
      sub: (item) => {
        return (
          <Tippy
            interactive={true}
            offset={[-130, -310]}
            trigger="click"
            content={<UploadFile old={image} onSuccess={handleUpdateSuccess} documentId={documentId} />}
          >
            {item}
          </Tippy>
        );
      },
    },
    {
      text: i18n('pictures.dropdown.item.edit-name'),
      icon: 'fa-pen',
      onClick: () => {
        setCurrentImage(image);
        setShowImgInfo(false);
        setImageName(image.filename);
        setModalNewNameOpen(true);
      },
    },
    {
      text: i18n('pictures.dropdown.item.show-details'),
      icon: 'fa-info-circle',
      onClick: () => {
        setCurrentImage(image);
        setShowImgInfo(true);
      },
    },
    {
      text: i18n('pictures.dropdown.item.delete-picture'),
      icon: 'fa-trash',
      onClick: async () => {
        setImageToDelete(image.revisionId);
        setImageInUseError(false);
      },
    },
  ];

  const handleConfirmDelete = async () => {
    return request
      .post(`/gaby/documents/${documentId}/actions`, {
        action: 'delete_image',
        revisionId: imageToDelete,
      })
      .then(() => {
        setShowImgInfo(false);
        setImageToDelete(null);
        reloadData();
      })
      .catch((err) => {
        const response = JSON.parse(err.request.response);
        if (response.message === 'image-in-use') {
          setImageInUseError(true);
        } else {
          throw err;
        }
      });
  };

  const DetailView = () => {
    return showImgInfo ? (
      <>
        <div className="close-info-btn" onClick={() => setShowImgInfo(false)}>
          <i className="fa-regular fa-xmark" />
        </div>
        <div className="detail-view pictures">
          <p className="title">{currentImage.filename}</p>
          <div className="img-wrapper">
            <DataImage {...currentImage} />
          </div>
          <div className="info mt-4">
            <div className="columns two mb-3">
              <p className="title">{i18n('pictures.details-view.type.title')}</p>
              <p>{currentImage.fileType}</p>
            </div>
            <div className="columns two mb-3">
              <p className="title">{i18n('pictures.details-view.title.uploaded-at')}</p>
              <p>{formatDate(currentImage.createdAt)}</p>
            </div>
          </div>
        </div>
      </>
    ) : null;
  };

  const reloadData = useCallback(() => {
    request.get(`/gaby/documents?superId=${documentId}&current=true`).then((res) => setDoc(res.data[0]));
  }, [documentId]);

  useEffect(reloadData, [reloadData]);

  const handleUpdateSuccess = async ({ data }, old) => {
    await request.post(`/gaby/documents/${documentId}/actions`, {
      action: 'update_image',
      revisionId: old.revisionId,
      data: {
        revisionId: data.revisionId,
        superId: data.superId,
      },
    });

    await reloadData();
  };

  const handleCreateSuccess = async ({ data }) => {
    await request.post(`/gaby/documents/${documentId}/actions`, {
      action: 'create_image',
      data: {
        revisionId: data.revisionId,
        superId: data.superId,
      },
    });

    await reloadData();
  };

  const toggleListType = () => {
    setListType(listType === LIST_TYPES[0] ? LIST_TYPES[1] : LIST_TYPES[0]);
  };

  const handleImageNameUpdate = (event) => {
    setImageName(event.target.value);
  };

  function handleClose() {
    setErrorImageName(false);
    setModalNewNameOpen(false);
  }

  const handleImageNameChangeSubmit = async (event) => {
    event.preventDefault();
    if (!validName(imageName)) {
      setErrorImageName(true);
      return;
    }
    changeImageName({
      documentSuperId: currentImage.documentSuperId,
      imageSuperId: currentImage.superId,
      imageRevisionId: currentImage.revisionId,
      newName: imageName,
    });
    setErrorImageName(false);
    setModalNewNameOpen(false);
  };

  const ImageList = () => {
    const images = (doc.content.images ?? []).map((image) => (
      <Picture listType={listType} image={image} key={image.revisionId} imageMenuItems={imageMenuItems} />
    ));

    switch (listType) {
      case 'box':
        return (
          <div className="image--list">
            <div className="columns five gutter-12">{images}</div>
          </div>
        );
      case 'list':
      default:
        return (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{i18n('pictures.list-selector.list.thead.name')}</th>
                <th>{i18n('pictures.list-selector.list.thead.uploaded-at')}</th>
                <th>{i18n('pictures.list-selector.list.thead.actions')}</th>
              </tr>
            </thead>
            <tbody>{images}</tbody>
          </table>
        );
    }
  };

  return (
    <div>
      <style type="text/css">{css.data?.exposedCss}</style>
      <DetailView />
      <div className="overview--image">
        <div className="overview--image--menu">
          <button className="fab clear justify-self-end" onClick={toggleListType}>
            <i className="fa-regular fa-list" />
          </button>
          <div className="divider vertical" />
          <Tippy
            interactive={true}
            offset={[-130, 10]}
            placement="bottom"
            trigger="click"
            content={<UploadFile onSuccess={handleCreateSuccess} documentId={documentId} />}
          >
            <button className="fab">
              <i className="fa-regular fa-plus font-15" />
            </button>
          </Tippy>
        </div>
        <div className="overview--image--content">{doc ? <ImageList /> : <Spinner />}</div>
      </div>
      <Modal show={modalNewNameOpen}>
        <div className="header d-flex">
          <h3>{i18n('project-list.new-name.modal.title')}</h3>
          <div
            className="close"
            onClick={() => {
              handleClose();
            }}
          >
            <i className="fa-regular fa-xmark" />
          </div>
        </div>
        <div className="body ">
          <form onSubmit={handleImageNameChangeSubmit} className={errorImageName ? 'form error' : 'form'}>
            <div className="mt-4">
              <label>
                <input type="text" ref={(o) => o?.focus()} value={imageName} placeholder="" onChange={handleImageNameUpdate} />
              </label>
              <span className="text-help">{errorImageName ? i18n('project-list.new-name.field.nameError') : ''}</span>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="button clear"
                onClick={() => {
                  handleClose();
                }}
              >
                {i18n('common.button.cancel')}
              </button>
              <button type="submit" className="button primary">
                {i18n('common.button.save')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <ConfirmationModal
        message={
          <>
            <i className="fa-regular fa-trash-can fs-1 mb-4" />
            <h3 className="confirm-delete-header">{i18n('pictures.confirm-delete')}</h3>
            <span className="text-gray-900 fs-7">{i18n('pictures.confirm-delete-info')}</span>
            {imageInUseError && <div className="text-red-500">{i18n('pictures.confirm-delete.image-in-use-error')}</div>}
          </>
        }
        title={' '}
        show={!!imageToDelete}
        onConfirm={handleConfirmDelete}
        onDecline={() => setImageToDelete(null)}
      />
    </div>
  );
};

export default Pictures;
