import React, { useContext, useState } from 'react';
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { NavbarDefault, FileZone, Modal } from 'app/components';

import Users from './users/Users';
import { UserContext } from 'app/state/contexts/UserContext';
import { IfHasPermission } from 'app/components';
import { i18n } from 'app/utils/i18n';
import { SupportModal, IconButton } from 'app/components';
import { useGetDocuments, queryKeys, useNewDocument } from 'app/api/documents/document-queries';
import { useQueryClient } from 'react-query';
import ProjectList from './ProjectList';
import Tippy from '@tippyjs/react';
import { showMessage } from '../utils/messages';

const Dashboard = () => {
  const { selectedOrganization, organizations } = useContext(UserContext);
  const [documentName, setDocumentName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [errorDocumentName, setErrorDocumentName] = useState(false);
  const newDocument = useNewDocument();

  let { path } = useRouteMatch();
  const queryClient = useQueryClient();

  const { data: documents } = useGetDocuments(selectedOrganization);

  const handleCreateSuccess = (data, old) => {
    queryClient.invalidateQueries([queryKeys.documents, { selectedOrganization }]);
    showMessage('success', i18n('dashboard.document-import-success'));
  };

  const handleNewProjectSubmit = (event) => {
    event.preventDefault();
    if (!documentName) {
      setErrorDocumentName(true);
      return;
    }

    let invalidDocumentName = documentName.trim().length < 1 || documentName.trim().length > 64;
    if (invalidDocumentName) {
      setErrorDocumentName(true);
      return;
    }

    newDocument.mutate(
      {
        documentName: documentName.trim(),
        selectedOrganization: selectedOrganization,
      },
      {
        onSuccess: () => {
          setModalOpen(false);
          setDocumentName('');
          setErrorDocumentName(false);
        },
      }
    );
  };

  if (!selectedOrganization) {
    return (
      <div>
        <div>{i18n('common.no-organization-message')}</div>
        <NavbarDefault />
      </div>
    );
  }

  const UploadFile = ({ old, onSuccess }) => {
    return (
      <div className="add-new-popup">
        <FileZone type="json" selectedOrganization={selectedOrganization} old={old} url="/gaby/documents/import" onSuccess={onSuccess} />
      </div>
    );
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDocumentName('');
    setErrorDocumentName(false);
  };

  return (
    <div className="project--details grid--container full--height columns">
      <div className="content">
        <div className="content-title">
          <h1>{organizations.find((o) => o.id === selectedOrganization)?.name}</h1>
        </div>
        <div className="menu--layout">
          <NavLink to="" exact>
            {i18n('dashboard.nav-link.overview')}
          </NavLink>
          <IfHasPermission permission="ORGANIZATION_CREATE">
            <NavLink to={`users`}>{i18n('dashboard.nav-link.users')}</NavLink>
          </IfHasPermission>
        </div>

        <Switch>
          <Route path={path} exact>
            <div className="project--overview">
              <div className="columns two mb-2">
                <h2>{i18n('dashboard.ongoing-projects.title')}</h2>
                <div className="action--align--left space-x-2">
                  <IfHasPermission permission="DOCUMENT_IMPORT">
                    <Tippy interactive={true} offset={[-130, 10]} placement="bottom" trigger="click" content={<UploadFile onSuccess={handleCreateSuccess} />}>
                      <button className="fab">
                        <i className="fa-regular fa-file-import font-15" />
                      </button>
                    </Tippy>
                  </IfHasPermission>
                  <IfHasPermission permission="DOCUMENT_CREATE">
                    <IconButton icon="plus" onClick={() => setModalOpen(true)} />
                  </IfHasPermission>
                </div>
              </div>
              <ProjectList projects={documents} />
            </div>
          </Route>
          <Route path={`${path}users`} component={Users} />
        </Switch>
        <NavbarDefault />
        <SupportModal />
      </div>

      <Modal titleLabelKey="dashboard.new-project.modal.title" show={modalOpen} onClose={handleModalClose}>
        <form onSubmit={handleNewProjectSubmit}>
          <input type="text" className="form-control mb-4" value={documentName} onChange={(event) => setDocumentName(event.target.value)} />
          <span className="text-help">{errorDocumentName ? i18n('dashboard.new-project.modal.field.nameError') : ''}</span>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="button clear"
              onClick={() => {
                setModalOpen(false);
                setDocumentName('');
                setErrorDocumentName(false);
              }}
            >
              {i18n('common.button.cancel')}
            </button>
            <button className="btn btn-primary">{i18n('common.button.save')}</button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Dashboard;
