import { DiffContext } from 'app/state/contexts/DiffContext';
import React, { useContext } from 'react';
import ReactDataSheet from 'libs/react-datasheet';

export const DefaultTable = ({ id, data, locale, diff, side }) => {
  const { diffState } = useContext(DiffContext);
  const isSelected = diffState?.right[data.superId]?.selected;

  return (
    <div id={id} contentEditable={false}>
      <ReactDataSheet
        readOnly={true}
        className="table--reference-data"
        data={data.selectionContent}
        valueRenderer={(cell) => {
          if (!!locale && !!cell.locales && Object.keys(cell.locales).length) {
            /*
              This (next 3 rows) is a temporary bug fix. All the dates should be shown in swe format.
            */
            if (cell.rawData.t === 'd') {
              return cell.locales['sv-SE'] || cell.value;
            }
            return cell.locales[locale] || cell.value;
          }
          return cell.value;
        }}
        cellRenderer={(props) => {
          const { row, col, cell, children, attributesRenderer, updated, editing, className: baseClassName, ...rest } = props;

          let style = {};
          const changed = diff?.[row]?.[col]?.some((diff) => diff[0] !== 0);
          const className = changed && side === 'right' ? `${baseClassName} ${isSelected ? 'diffed-change-selected' : 'diffed-change-added'}` : baseClassName;

          let css = `${!!cell.format && cell.format.includes('.') ? cell.format.replace('.', '') : ''} ${!!cell.background ? cell.background : ''} ${
            !!cell.border ? cell.border : ''
          }`;

          if (cell.styling === 'bold') {
            return (
              <td className={`${className} ${css}`} {...rest} style={style}>
                <b>{props.children}</b>
              </td>
            );
          } else if (cell.styling === 'italic') {
            return (
              <td className={`${className} ${css}`} {...rest} style={style}>
                <i>{props.children}</i>
              </td>
            );
          } else if (cell.styling === 'underline') {
            /* <u> is not valid xhtml, use class instead */
            return (
              <td className={`${className} ${css}`} {...rest} style={style}>
                <span className="xrp-underline">{props.children}</span>
              </td>
            );
          }

          if (cell.format === 'thead') {
            return (
              <th className={`${className} ${css}`} {...rest} style={style}>
                {props.children}
              </th>
            );
          }

          return (
            <td className={`${className} ${css}`} {...rest} style={style}>
              {props.children}
            </td>
          );
        }}
      />
    </div>
  );
};
