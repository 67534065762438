import React, { useEffect, useState } from 'react';
import { i18n } from 'app/utils/i18n';
import { Modal, SimpleTable } from 'app/components';
import { useChangeDocumentName } from 'app/api/documents/document-queries';

function validName(documentName) {
  return documentName.trim().length > 0 && documentName.trim().length < 65;
}

const ProjectList = ({ projects }) => {
  const { mutate: changeDocumentName, isSuccess } = useChangeDocumentName();
  const [modalNewNameOpen, setModalNewNameOpen] = useState(false);
  const [activeDocument, setActiveDocument] = useState();
  const [errorDocumentName, setErrorDocumentName] = useState(false);
  const [documentName, setDocumentName] = useState('');

  useEffect(() => {
    if (isSuccess) {
      document.name = documentName;
      setModalNewNameOpen(false);
      setDocumentName('');
      setErrorDocumentName(false);
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  const handleNameEdit = (project) => {
    setActiveDocument(project);
    setDocumentName(project.name);
    setModalNewNameOpen(true);
  };

  function handleClose() {
    setErrorDocumentName(false);
    setModalNewNameOpen(false);
  }

  const handleNameChangeSubmit = (e) => {
    e.preventDefault();
    if (!validName(documentName)) {
      setErrorDocumentName(true);
      return;
    }
    changeDocumentName({ documentSuperId: activeDocument.superId, documentRevisionId: activeDocument.revisionId, newName: documentName });
  };

  if (!projects) {
    return <div>Inga projekt</div>;
  }

  const items = projects.map((project) => ({
    title: project.name,
    linkTo: `/documents/${project.superId}`,
    dropdownItems: [{ title: i18n('project-list.document.edit-name'), icon: 'pen', onClick: () => handleNameEdit(project) }],
  }));

  return (
    <>
      <SimpleTable items={items} />
      <Modal titleLabelKey="project-list.new-name.modal.title" show={modalNewNameOpen}>
        <form onSubmit={handleNameChangeSubmit}>
          <div className="mt-4">
            <input type="text" className="form-control" ref={(o) => o?.focus()} value={documentName} onChange={(e) => setDocumentName(e.target.value)} />
            <span className="text-help">{errorDocumentName ? i18n('project-list.new-name.field.nameError') : ''}</span>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button
              type="button"
              className="button clear"
              onClick={() => {
                handleClose();
              }}
            >
              {i18n('common.button.cancel')}
            </button>
            <button type="submit" className="button primary">
              {i18n('common.button.save')}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ProjectList;
