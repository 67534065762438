import { useEditorSelector } from 'app/state/contexts/EditorContext';
import { i18n } from 'app/utils/i18n';
import { useGetDocumentTableOfContent } from 'app/api/documents/document-queries';
import { calulcateCurrentPage } from './utils';

const HeadingsArea = ({ pageId, options }) => {
  const pDocument = useEditorSelector((editor) => editor.pDocument);
  const locale = useEditorSelector((editor) => editor.locale);
  const section = useEditorSelector((editor) => editor.section);
  const { data: tableOfContentDocument } = useGetDocumentTableOfContent(pDocument.revisionId ?? null, { locale });
  const { data: tableOfContentSection } = useGetDocumentTableOfContent(pDocument.revisionId ?? null, { locale, sectionSuperId: section.superId });

  if (!tableOfContentDocument?.content) {
    return null;
  }

  const overrideHeadings = options[locale]?.overrides || [];
  const excludes = overrideHeadings.filter((override) => override.text === null).map((override) => override.uuid);
  const headers = tableOfContentDocument.content.filter((header) => !excludes.includes(header.uuid));

  const { physicalPage } = calulcateCurrentPage(pDocument, section, pageId, tableOfContentSection);
  const currentRange = calcCurrentRange(headers, physicalPage);

  return (
    <div className="subarea-headers">
      <ol role="menu" aria-orientation="horizontal" style={{ userSelect: 'none' }}>
        {headers.map((header) => (
          <li key={header.uuid} role="presentation">
            <a role="menuitem" aria-current={currentRange.includes(header.pageNumber)} href={`#id-${header.pageId}`}>
              {overrideHeadings.find((override) => override.uuid === header.uuid)?.text || header.name}
            </a>
          </li>
        ))}
      </ol>
    </div>
  );
};

const calcCurrentRange = (headers, physicalPage) => {
  let startRange = 0;
  let endRange = 0;

  for (const header of headers) {
    if (header.pageNumber <= physicalPage) {
      startRange = header.pageNumber;
      endRange = header.pageNumber;
    }
    if (header.pageNumber > physicalPage) {
      endRange = header.pageNumber;
      break;
    }
  }

  return Array.from({ length: endRange - startRange || 1 }, (_, index) => startRange + index);
};

const Area = ({ pageId, areaConfig }) => {
  switch (areaConfig?.type) {
    case 'simple':
      return <SimpleArea pageId={pageId} options={areaConfig.options} />;
    case 'headings':
      return <HeadingsArea pageId={pageId} options={areaConfig.options} />;
    default:
      return null;
  }
};

const SimpleArea = ({ pageId, options }) => {
  const { pageNumber, metadataText } = options;

  const PageNumber = ({ pageId }) => {
    const pDocument = useEditorSelector((editor) => editor.pDocument);
    const locale = useEditorSelector((editor) => editor.locale);
    const section = useEditorSelector((editor) => editor.section);
    const { data } = useGetDocumentTableOfContent(pDocument.revisionId ?? null, { locale, sectionSuperId: section.superId });

    if (data === null) {
      return <div className={i18n('doc-editor.page-number.placeholder')}></div>;
    }

    if (!pDocument.content?.footer?.options.pageNumber) {
      return null;
    }

    const pageNumbering = pDocument.content?.pageNumbering;

    const { physicalPage, documentPage } = calulcateCurrentPage(pDocument, section, pageId, data);

    if (physicalPage < pageNumbering?.showFrom) {
      return <div className="subarea-pagenumber"></div>;
    }

    return <div className="subarea-pagenumber">{documentPage || null}</div>;
  };

  const Texts = ({ textKeys }) => {
    const pDocument = useEditorSelector((editor) => editor.pDocument);
    const locale = useEditorSelector((editor) => editor.locale);

    const texts = pDocument.content?.metadata?.texts?.[locale];

    const usedTexts = Object.keys(texts).filter((textKey) => textKeys.includes(textKey));

    return (
      <div className="subarea-texts">
        {usedTexts.map((textKey) => (
          <div key={textKey} className={`subarea-text-${textKey}`}>
            {texts[textKey]}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {pageNumber ? <PageNumber pageId={pageId} /> : null}
      {metadataText && metadataText.length > 0 ? <Texts textKeys={metadataText} /> : null}
    </>
  );
};

export default Area;
